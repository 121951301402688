import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import user from "@/store/user";

Vue.use(Vuex);

export default new Vuex.Store({

  modules: {
    user,

  },
  plugins: [createPersistedState({
    storage:window.sessionStorage
  })],
});
