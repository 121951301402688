
import router from "@/router";

const user = {

    state: {
        businessInfo: '',
        checkOutDetails: '',
        verifyTransaction: '',
        auditLog: '',
        auditDetail: '',
        initializeTransfer: '',
        timer: '',
        oldTransfer: '',
        transdetails:'',
        paymentProfile:'',


    },
    getters: {
        getBusinessInfo: (state) => state.businessInfo,
        getCheckOutDetails: (state) => state.checkOutDetails,
        getVerifiedTransaction: (state) => state.verifyTransaction,
        getAuditLog: (state) => state.auditLog,
        getAuditDetail: (state) => state.auditDetail,
        getIntializeTransfer: (state) => state.initializeTransfer,
        getTransferTime: (state) => state.timer,
        getOldTransfer:(state)=>state.oldTransfer,
        getTransactionDetails:state=>state.transdetails,
        getPaymentProfile:state=>state.paymentProfile,



    },
    mutations:{

        //update business info
        updateBusinessInfo:(state,data)=>state.businessInfo=data,

        //update payment checkout details
        updateCheckOutDetails:(state,data) => state.checkOutDetails=data,

        //update verified transaction
         updateVerifiedTransaction:(state,data) => state.verifyTransaction=data,

        //update audit log
        updateAuditLog:(state,data)=>state.auditLog=data,

        //update audit detail
        updateAuditLogDetail:(state,data)=>state.auditDetail=data,

        //update one app transfer account details
        updateInitializeTransfer:(state,data)=>state.initializeTransfer = data,

        //update old transfer
        updateOldTransfer:(state,data)=>state.oldTransfer=data,

        //update transfer countdown
        updateCountDown: (state,data)=>{
            if(parseInt(state.timer) === 0) return
            state.timer -= data
        },

        //update transfer timer
        updateTransferTimer:(state,time)=>{
            state.timer = parseInt(time)
        },

        //update transaction details
        updateTransactionDetail:(state,data)=>state.transdetails=data,

        //update payment profile
        updatePaymentProfile:(state,data) =>state.paymentProfile=data,

    },
    actions:{
        //fetch business info
        fetchBusinessInfo:async(context,paylink)=>{
            try {
                let formData = new FormData();
                formData.append('plink', paylink)
                await fetch(`https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/thebusinfo`, {
                    method: 'POST',
                    body:formData
                })
                    .then(response=>response.json())
                    .then(response=>{
                        if(response.status) return context.commit('updateBusinessInfo',response)
                        return router.push({name:'notFound'})
                    }).catch(e=>console.log(e.message))
            } catch (e) {
                console.log(e.message)
            }
        },

        //initiate payment
        initiatePayment: async(context,data)=>{
            try{
                let formData = new FormData()
                formData.append('amount',data.amount)
                formData.append('fname',data.fname)
                formData.append('lname',data.lname)
                formData.append('phn',data.phn)
                formData.append('currency',data.currency)
                formData.append('email',data.email)
                formData.append('pagetype',data.pagetype)
                formData.append('businessid',data.bisid)
                formData.append('pageid',data.pageid)

                return  await fetch('https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/initiatepayment',{
                    method:'POST',
                    body:formData
                })
            }catch(e){
                console.log(e.message)
            }




        },

        //fetch check out details
        fetchCheckOutDetails:async(context,data)=>{
            try{
                let formData = new FormData()
                formData.append('token',data.token)
                formData.append('reference',data.reference)


                 await fetch('https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/loadthecheckout',{
                    method:'POST',
                    body:formData
                })
                     .then(response=>response.json())
                     .then(async response=>{
                         if(response.status===false){
                             await window.alert(response.message)
                             await window.sessionStorage.clear()
                             if(!!data.href)window.parent.location.href=data.href
                             return false


                             // return
                             // return router.push({name:'paymentPage',params:{pagename:sessionStorage.payment_link}})

                         }
                         //console.log(response)
                         return context.commit('updateCheckOutDetails', response)
                     }).catch(e=>console.log(e.message))
            }catch(e){
                console.log(e.message)
            }

        },

        //process transactions
        processTransaction:async (context,data)=>{
            try{
                let formData = new FormData()
                formData.append('token',data.token)
                formData.append('reference',data.reference)
                formData.append('gateway',data.gateway)
                formData.append('redirectlink',data.redirectlink)


               return  await fetch('https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/process-payment',{
                    method:'POST',
                    body:formData
                })
            }catch(e){
                console.log(e.message)
            }

        },

        //verify transactions
        verifyTransaction:async(context,data)=>{
            try{
                let formData = new FormData()

                formData.append('reference',data.reference)
                formData.append('confirm',data.confirm)
                formData.append('gateway',data.gateway)
                formData.append('gatewayref',data.gatewayref)
                formData.append('gatewaystatus',data.gatewaystatus)


                  await fetch('https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/verify-transaction',{
                    method:'POST',
                    body:formData
                })  .then(response=>response.json())
                      .then(response=>{
                          return context.commit('updateVerifiedTransaction', response)
                      }).catch(e=>console.log(e.message))
            }catch(e){
                console.log(e.message)
            }
        },

        //verify user account
        verifyUserLogin:async(context,data)=>{
            try{
                let formData = new FormData()
                formData.append('ref',data.ref)
                formData.append('token',data.token)
                formData.append('email',data.email)
                formData.append('pwd',data.pwd)


               return await fetch('https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/verifypaymentlogin',{
                    method:'POST',
                    body:formData
                })
            }catch(e){
                console.log(e.message)
            }
        },

        //confirm login otp
        verifyOTP: async(context,data)=>{
            try{

                let formData = new FormData()
                formData.append('ref',data.ref)
                formData.append('token',data.token)
                formData.append('otp',data.otp)
                formData.append('appuserid',data.appuserid)


                return await fetch('https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/chckloginotp',{
                    method:'POST',
                    body:formData
                })
            }catch(e){
                console.log(e.message)
            }
        },

        //fetch audit log
        fetchAuditLog:async(context)=>{
            try{
                let formData = new FormData()

                formData.append("sesscode",context.getters.getUser.sesscode)
                formData.append("userid", context.getters.getUser.userid)
                formData.append("businessid", context.getters.getUser.business_id)


                await fetch('https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/auditlog',{
                    method:'POST',
                    body:formData
                })  .then(response=>response.json())
                    .then(response=>{
                         return context.commit('updateAuditLog', response)

                    }).catch(e=>console.log(e.message))
            }catch(e){
                console.log(e.message)
            }
        },

        //fetch audit log detail
        fetchAuditLogDetail:async(context,data)=>{
            try{
                let formData = new FormData()

                formData.append("sesscode",context.getters.getUser.sesscode)
                formData.append("userid", context.getters.getUser.userid)
                formData.append("businessid", context.getters.getUser.business_id)
                formData.append("audit_id", data.audit_id)


                await fetch('https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/auditdetails',{
                    method:'POST',
                    body:formData
                })  .then(response=>response.json())
                    .then(response=>{
                        return context.commit('updateAuditLogDetail', response)

                    }).catch(e=>console.log(e.message))
            }catch(e){
                console.log(e.message)
            }
        },

        //initialize transfer
        intializeTransfer:async (context,data)=>{
            try{
                let formData = new FormData()
                formData.append("reference",data.reference)
                formData.append("token", data.token)
                formData.append("gateway", data.gateway)

              return   await fetch('https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/oneappmethod',{
                    method:'POST',
                    body:formData
                })
                  .then(response=>response.json())
                  .then(response=>{
                      context.commit('updateInitializeTransfer', response)
                      context.commit('updateTransferTimer', response.countdown)
                      context.dispatch('transferTimeCountDown')




                  }).catch(e=>console.log(e.message))
            }catch(e){
                console.log(e.message)
            }
        },

        //transfer time out countdown
        transferTimeCountDown:async (context)=>{
            setInterval(async()=>{
                if(context.getters.getTransferTime<=0)return
                if(context.getters.getTransferTime <=0){
                    clearInterval()
                    return
                }
                context.commit('updateCountDown',1)


            },1005)
        },


        //check dynamic transfer
        checkDynamicTransfer:async(context, data)=>{
            try{
                let formData = new FormData()
                formData.append("reference",data.reference)
                formData.append("token", data.token)
                formData.append("accntno", data.accntno)
                formData.append("amount", data.amount)

                return   await fetch('https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/checkdynamic-transfer',{
                    method:'POST',
                    body:formData
                })

            }catch(e){
                console.log(e.message)
            }
        },

        //fetch transaction  details
        fetchTransactionDetails:async(context,data)=>{
            try{
                let formData = new FormData()
                formData.append('reference',data.reference)


                await fetch('https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/print-receipt',{
                    method:'POST',
                    body:formData
                })
                    .then(response=>response.json())
                    .then(async response=>{
                        if(response.status) return context.commit('updateTransactionDetail', response)
                    }).catch(e=>console.log(e.message))
            }catch(e){
                console.log(e.message)
            }

        },
    },





}

export default user