import Vue from "vue";
import VueRouter from "vue-router";


Vue.use(VueRouter);

const routes = [

    
    
  {
    name:'home',
    path:'/redirect',
    component: ()=>import(/* webpackChunkName:"user-login" */  "@/views/home"),
    meta:{
      title:'1app Business Login - Get paid from anywhere, easily'
    }

  },


    //user navigations


  {
    name:'checkOut',
    path:'/checkout/:access_token/:reference',
    component: ()=>import(/* webpackChunkName:"user-pay-checkout" */"@/views/user/pay/checkOut"),
    meta:{
      title:'Check out   Page - 1app Business',
    },
    params:true
  },
  {
    name:'transactionVerifier',
    path:'/verify-transaction',
    component: ()=>import(/* webpackChunkName:"user-pay-verifier" */"@/views/user/pay/transactionVerifier"),
    meta:{
      title:'Check out   Page - 1app Business',
    },
    params:true
  },
  {
    name:'confirmTransaction',
    path:'/confirm-transaction',
    component: ()=>import(/* webpackChunkName:"user-pay-confirm-transaction" */"@/views/user/pay/confirmTransaction"),
    meta:{
      title:'Confirm Transaction   Page - 1app Business',
    },
    params:true
  },
  {
    name:'transferMethod',
    path:'/transfer/:token/:reference/:gateway',
    component: ()=>import(/* webpackChunkName:"user-pay-transfer" */"@/views/user/pay/transferMethod"),
    meta:{
      title:'Transfer Payment   Page - 1app Business',
    },
    params:true
  },
  {
    name:'paymentPage',
    path:'/:pagename?',
    component: ()=>import(/* webpackChunkName:"user-pay-page" */"@/views/user/pay/paymentPage"),
    meta:{
      title:'Payment  Page - 1app Business',
    },
    params:true
  },





    //404 not found
  {
    name:'notFound',
    path:'*',
    component:()=>import(/*webpackChunkName: "error"*/ "@/views/error/notFound")
  }


];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
