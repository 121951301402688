import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";



Vue.config.productionTip = false;

export const   bus = new Vue();


//authorization verification for each route

//verify access on routing to each page
router.beforeEach((to, from, next) => {

  if (to.meta.auth === 'user' && store.getters.getUser==="") next({ name: 'home' });


  else next()
})

//set the title of each webpage on routing and redirect to the top
router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : '1app Business';
  window.scroll(0,0)

  next();
})

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
