<template>
  <v-app style=" background-color: rgba(255,255,255,0)!important;">
    <router-view />
  </v-app>
</template>

<script>

import ('@/assets/css/w3.css');
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
  .heading{
    font-family: 'Poppins', sans-serif!important;

  }
  body{
    font-family: 'Open Sans', sans-serif!important;
    color: #444!important;
    background-color: rgba(255,255,255,0)!important;
  }
</style>
